import React, { Component } from 'react'
import { connect } from 'react-redux'
import SEO from '@/components/SEO/SEO'
import FeedHeader from '@/components/FeedHeader/FeedHeader'
import PostThumb from '@/components/PostThumb/PostThumb'
import ContentGrid from '@/components/ContentGrid/ContentGrid'
import Pagination from '@/components/Pagination/Pagination'
import { prepItem, createPaginationLinks } from '@/utils'
import { setupPage } from '@/utils/common'

class Articles extends Component {
	constructor(props) {
		super(props)

		setupPage(props)
	}

	render() {
		const {
			pageContext: { group, index, first, last, pageCount, pathPrefix },
			location
		} = this.props

		const entries = prepItem(group)
		const { pages, previousUrl, nextUrl } = createPaginationLinks({
			index,
			pageCount,
			pathPrefix
		})

		return (
			<div className="pb-2">
				<SEO
					title="Articles"
					description="explore all our articles"
					socialTitle="explore all our articles"
					socialDesc="explore all our articles"
					url={location.href}
				/>
				<FeedHeader title="articles">
					<p className="text-xl-fluid">explore all our articles</p>
				</FeedHeader>
				<ContentGrid
					content={entries}
					render={item => <PostThumb {...item} />}
				/>

				{pages.length > 1 && (
					<div className="wrapper">
						<Pagination
							prev={`${pathPrefix}/${previousUrl}`}
							next={`${pathPrefix}/${nextUrl}`}
							first={first}
							last={last}
							pages={pages}
						/>
					</div>
				)}
			</div>
		)
	}
}

const mapState = state => ({
	...state.player
})

const mapDispatch = ({ player: { setPageType, setAudioBarVisibility } }) => ({
	setPageType,
	setAudioBarVisibility
})

export default connect(
	mapState,
	mapDispatch
)(Articles)
